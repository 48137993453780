import React, { Fragment, useState } from 'react'
import { pt_br, transformarArrayEmObjeto, dateAndHour } from '../../Basicos/funcoes';
import { diaDaSemanaDaDataString } from '../../../services/calendarioOferta';
import { Row, Col } from "reactstrap";
import { corDoTexto } from '../../../services/coresNoBlocoPadrao';
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
// import uniLogo from "../../images/ic_excel.png"
import uniLogo from "../../css/images/logo-unichristus.jpg"

function pegarDiaDaSemana(horario, diaDaSemana, estruturaDoGrid) {
    return estruturaDoGrid?.[horario.id] && estruturaDoGrid?.[horario.id][diaDaSemana]
        ? estruturaDoGrid?.[horario.id][diaDaSemana]
        : [];
}

function blocoSelecionado(horario, diaDaSemana, { horario_do_curso_id, dia_da_semana }) {
    return horario.id == horario_do_curso_id && dia_da_semana == diaDaSemana;
}

const PDFSemanaPadrao = ({
    inicioDaSemana = 1,
    finalDaSemana = 5,
    colClassname = "calendario-padrao",
    horariosCurso,
    horariosDefinidos = [],
    tipoAtividades,
    horariosAulas,
    selecionarHorario = () => { },
    datasInstitucionais = [],
    datasInstitucionaisPDF = [],
    horarioSelecionado = {},
    deletarHorario = () => { },
    temMenu = true,
    abrirModalAssociar,
    abrirModalVisualizar = () => { },
    deletarHorarioModal = () => { },
    aulas = [],
    éTipoOferta = false,
    éConsulta = false,
    tipoOferta: {
        mudarSemana = () => { },
        mudarMes = () => { },
        semana,
    } = false,
    grupos,
    aulasPDF,
    semanasPDF,
    mesesPDF,
    periodoLetivo,
    temas,
    dataUltimaAtualizacao
}) => {
    const [modalTrocarAula, setModalTrocarAula] = useState({
        isOpen: false,
        HorarioAulas: []
    });

    //Questao de perfomance é transformado em obj, para ser mais facil acessar;
    const tipoAtividadesObj = transformarArrayEmObjeto(tipoAtividades);

    const estruturaDoGrid = aulasPDF?.map((aulasDia) => {
        const diaEstrutura = {}; // Inicializa a estrutura para este dia
        aulasDia.forEach((elemento) => {
            const estruturaHorarioRow = diaEstrutura[elemento.horario_do_curso_id] ?? {};
            estruturaHorarioRow[elemento.dia_da_semana] = estruturaHorarioRow[elemento.dia_da_semana] ?? [];
            estruturaHorarioRow[elemento.dia_da_semana].push({ ...elemento });
            diaEstrutura[elemento.horario_do_curso_id] = estruturaHorarioRow;
        });
        return diaEstrutura;
    });

    const estruturaGridDatasEspeciais = datasInstitucionaisPDF?.map((dataInstitucionais) => {
        const diaEstrutura = {}; // Inicializa a estrutura para este dia
        dataInstitucionais
            .filter(dataInstitucional => dataInstitucional.tipo_de_data_id === 3 || dataInstitucional.tipo_de_data_id === 5 || dataInstitucional.tipo_de_data_id === 6) //Apenas feriados
            .forEach(dataInstitucional => {
                const diaDaSemana = diaDaSemanaDaDataString(dataInstitucional.data);
                horariosCurso.forEach(horarioCurso => {
                    const estruturaHorarioRow = diaEstrutura[horarioCurso.id] ?? {};
                    estruturaHorarioRow[diaDaSemana] = estruturaHorarioRow[diaDaSemana] ?? [];
                    estruturaHorarioRow[diaDaSemana].push(dataInstitucional);
                    diaEstrutura[horarioCurso.id] = estruturaHorarioRow;
                });
            });
        return diaEstrutura;
    });

    const estruturaGridDatasProvas = {};

    const DiaDaSemanaLabel = ({ children, className }) => {
        return (
            <div
                className={`
                    pdf-align-content-middle-calendario calendario-padrao-legenda texto-secundario 
                    ${className ? className : "calendario-legenda-dias"}
                `}
            >
                {children}
            </div>
        );
    }

    const LegendasDiaDaSemanaComDomingoESabadoCol = ({ indice }) => {
        const dias = [];
        let semanaAtual = semanasPDF[indice]

        if (semanaAtual.length === 0)
            return dias;

        //Domingo
        dias.push(
            <DiaDaSemanaLabel
                className={"calendario-legenda-dias--bordaInferior"}
                key={pt_br.weekdaysLong[0]}
            >
                {`${pt_br.weekdaysLong[0]}/${semanaAtual[0].getDate()}`}
            </DiaDaSemanaLabel>
        );

        //Segunda - Sexta
        for (let indice = 1; indice <= 5; indice++) {
            const diaDaSemana = pt_br.weekdaysLong[indice];
            dias.push(
                <DiaDaSemanaLabel
                    className={"calendario-legenda-dias--bordaInferior"}
                    key={diaDaSemana}
                >
                    {`${diaDaSemana}/${semanaAtual[indice].getDate()}`}
                </DiaDaSemanaLabel>
            );
        }

        //Sábado
        dias.push(
            <DiaDaSemanaLabel
                key={pt_br.weekdaysLong[6]}
                className={"calendario-legenda-dias--bordaInferior"}
            >
                {`${pt_br.weekdaysLong[6]}/${semanaAtual[6].getDate()}`}
            </DiaDaSemanaLabel>
        );

        return dias;
    };

    const LegendaHorarioRow = ({ horario, fim, inicio }) => {
        const [codHorario, turno] = horario.descricao.split(' ');
        const descricao = `${codHorario} (${inicio.slice(0, -3)} às ${fim.slice(0, -3)})`
        return (
            <div className="pdf-calendario-horario-aula" style={{ display: "flex" }}>
                <div style={{ background: "var(--cor-text-secundario)", color: "white", flexGrow: "1", minWidth: "50%" }}>
                    <span className="calendario-padrao-legenda-horario calendario-padrao-legenda">{turno.toUpperCase()}</span>
                </div>
                <div style={{ background: "var(--cor-horario-calendario)", color: "black", flexGrow: "1", minWidth: "50%" }}>
                    <span className="calendario-padrao-legenda-horario calendario-padrao-legenda">{descricao}</span>
                </div>
            </div>
        );
    };

    const horariosRow = (horario, index) => {
        const dias = [];
        for (let diaDaSemana = inicioDaSemana; diaDaSemana <= finalDaSemana; diaDaSemana++) {
            dias.push(
                <BlocoPadrao
                    key={diaDaSemana}
                    grupos={grupos}
                    horario={horario}
                    diaDaSemana={diaDaSemana}
                    estruturaDoGrid={estruturaDoGrid?.[index]}
                    estruturaGridDatasEspeciais={estruturaGridDatasEspeciais?.[index]}
                    estruturaGridDatasProvas={estruturaGridDatasProvas}
                    selecionarHorario={selecionarHorario}
                    tipoAtividadesObj={tipoAtividadesObj}
                    horarioSelecionado={horarioSelecionado}
                    deletarHorario={deletarHorario}
                    éTipoOferta={éTipoOferta}
                    horarioAulas={aulasPDF[index]}
                    index={index}
                    abrirModalAssociar={abrirModalAssociar}
                    abrirModalVisualizar={abrirModalVisualizar}
                    deletarHorarioModal={deletarHorarioModal}
                    aulas={aulas}
                    temMenu={temMenu}
                    éConsulta={éConsulta}
                    modalTrocarAula={modalTrocarAula}
                    setModalTrocarAula={setModalTrocarAula}
                />
            );
        };

        return dias;
    }

    function BlocoPadrao({
        grupos,
        horario,
        éTipoOferta,
        diaDaSemana,
        estruturaDoGrid,
        estruturaGridDatasEspeciais,
        estruturaGridDatasProvas,
        selecionarHorario,
        tipoAtividadesObj,
        horarioSelecionado,
        temMenu,
        éConsulta,
        index,
        aulas
    }) {
        const diaDaSemanaEstrutura = pegarDiaDaSemana(horario, diaDaSemana, estruturaDoGrid);
        const classSelecionado = blocoSelecionado(horario, diaDaSemana, horarioSelecionado)
            ? "calendario-padrao-selecionado"
            : "";
        let atividadeExisteNobloco = false;

        const tipoDeAtividadesDoDia = diaDaSemanaEstrutura.map((elemento, indice) => {
            const tipoAtividade = tipoAtividadesObj[elemento.tipo_atividade_id];

            const classSelecionadoAtividade = horarioSelecionado.tipo_atividade_id === elemento.tipo_atividade_id
                ? classSelecionado
                : "";

            atividadeExisteNobloco = atividadeExisteNobloco
                ? atividadeExisteNobloco
                : horarioSelecionado.tipo_atividade_id === elemento.tipo_atividade_id;

            let borderMaisDeDuasHrs = "";
            elemento?.horarios_aulas && elemento.horarios_aulas.forEach(horario_aula => {
                if (horario_aula.aula.duracao_minima > 2) {
                    borderMaisDeDuasHrs = `.5rem ridge ${tipoAtividade.cor}`;
                    const horarios_ids = Object.keys(estruturaDoGrid);
                    const findIndex = horarios_ids.findIndex(horario => elemento.horario_do_curso_id == horario)
                    const horarioOfertaEstrutura = estruturaDoGrid[horarios_ids[findIndex + 1]]?.[elemento.dia_da_semana]?.find(horario_aula => horario_aula.tipo_atividade_id == elemento.tipo_atividade_id)

                    if (horarioOfertaEstrutura && elemento.horario_do_curso_id == horario_aula.horario_periodo.horario_do_curso_id) {
                        const horarioAula = horarioOfertaEstrutura.horarios_aulas.find(horario => horario.aulas_id == horario_aula.aulas_id && horario_aula.grupos_por_aula === horario.grupos_por_aula);
                        if (!horarioAula) {
                            horarioOfertaEstrutura.horarios_aulas.push(horario_aula);
                        }
                    }
                }
            })
            const attributes = {
                className: `pdf-align-content-middle ${classSelecionadoAtividade}`,
                style: { color: "white", flex: "1 1 0px", backgroundColor: tipoAtividade.cor, height: '100%', width: '9.4rem' },
                onClick: () => selecionarHorario({ ...elemento, estruturasNoMesmoDia: diaDaSemanaEstrutura })
            };

            function ConteudoNoBloco() {
                if (elemento.horarios_aulas && elemento.horarios_aulas.length !== 0) {
                    return elemento.horarios_aulas.map((horarioAulas, indice) => (
                        <>
                            <Col className='remover-padding' key={indice}>
                                <TextoNoBlocoPadraoCasoTenhaAulaAssociada
                                    aula={horarioAulas}
                                    tipoAtividade={tipoAtividade}
                                    horario={horario}
                                    indice={indice}
                                />
                            </Col>
                        </>
                    ))
                } else {
                    const gruposSelecionados = grupos?.filter(el => elemento.grupos_ids?.includes(el.id))
                    return (
                        <Col>
                            {tipoAtividade.nome.toUpperCase()}
                            <br />
                            {gruposSelecionados?.map(el => `${el.descricao} `)}
                        </Col>
                    )
                }
            }
            function Bloco({ provided }) {
                return (
                    <span
                        className="pdf-calendario-padrao-legenda"
                        style={{
                            color: corDoTexto(tipoAtividade.cor),
                        }}
                    >
                        {
                            éTipoOferta && elemento.horarios_aulas && elemento.horarios_aulas.length !== 0 ?
                                <>
                                    <div className='pdf-titulo-bloco'>
                                        <span>{tipoAtividade.abreviatura}</span>
                                        <span>{elemento?.horario_curso?.inicio.substring(0, 5)}</span>
                                    </div>
                                    <span className="pdf-local-el ">{elemento?.horarios_aulas[0]?.aula?.titulo.toUpperCase()}</span>
                                </> : ''
                        }
                        < ConteudoNoBloco />
                    </span>
                );
            }

            if (temMenu) {
                return (
                    <div
                        key={indice}
                        className={attributes.className}
                        style={attributes.style}
                    >
                        <Bloco />
                    </div>
                );
            }

            return (
                <Fragment key={indice}>
                    <ContextMenuTrigger
                        id={`teste${elemento.id}`}
                        attributes={attributes}
                    >
                    </ContextMenuTrigger>

                    <ContextMenu
                        id={`teste${elemento.id}`}
                        className="horarioCalendario__contextMenu zindex-tooltip shadow-lg"
                    >
                    </ContextMenu>
                </Fragment>
            )
        });

        //para provas
        const diaDaSemanaEstruturaProvas = pegarDiaDaSemana(horario, diaDaSemana, estruturaGridDatasProvas)
        const diasDeProvas = diaDaSemanaEstruturaProvas.map((elemento, indice) => {
            return (
                <span
                    className="calendario-padrao-legenda"
                    style={{ color: corDoTexto(elemento.tipo_data.cor) }}
                >
                    {elemento.tipo_data.descricao.toUpperCase()}
                </span>
            );
        });

        //para Feriados e FDS Letivo
        const diaDaSemanaEstruturaEspecial = pegarDiaDaSemana(horario, diaDaSemana, estruturaGridDatasEspeciais)
        const estruturaFeriados = diaDaSemanaEstruturaEspecial.filter(elemento => (elemento.tipo_de_data_id === 3));

        const feriadoNoDia = estruturaFeriados.map((elemento, indice) => {
            return (
                <div
                    style={{ color: "white", flex: "1 1 0px", backgroundColor: elemento.tipo_data.cor, height: '100%', width: '9.4rem'}}
                    className='pdf-align-content-middle'
                >
                    <span
                        className="calendario-padrao-legenda"
                        style={{ color: corDoTexto(elemento.tipo_data.cor) }}
                    >
                        {elemento.tipo_data.descricao.toUpperCase()}
                    </span>
                </div>
            );
        });

        //Se não tiver atividade, o onclick deve ficar no parente
        const classNameEstruturaVazia = !atividadeExisteNobloco ? classSelecionado : "";

        return (
            <div
                className={`pdf-align-content-middle pdf-calendario-padrao-conteudo ${éConsulta ? "" : "cursor-ponteiro"} ${classNameEstruturaVazia}`}
                style={{ width: '9.5rem' }}
            >
                {tipoDeAtividadesDoDia.length == 0 && feriadoNoDia == 0 && diasDeProvas.length == 0
                    ?
                    <div
                        className={`pdf-align-content-middle`}
                        style={{ color: "white", flex: "1 1 0px", backgroundColor: 'white', height: diaDaSemanaEstrutura.length > 1 ? '' : '100%', width: '9.4rem' }}
                    >
                    </div>
                    :
                    <>
                        {tipoDeAtividadesDoDia}
                        {feriadoNoDia}
                        {diasDeProvas}
                    </>
                }
            </div >
        );
    };

    const nomeAgregadorGrupo = (aula) => {
        return (
            <>
                {aula?.grupos_por_aula.map((grupo, index) =>
                    <span key={index}>{grupo?.grupo?.descricao}{index + 1 == aula?.grupos_por_aula?.length ? '' : ', '}</span>
                )}
            </>
        )
    }

    function TextoNoBlocoPadraoCasoTenhaAulaAssociada({ aula, horario, indice }) {
        const divergenciaVazia = [{
            divergenciaDisponibilidade: false,
            divergenciaServico: false,
            semLocal: false,
            semProfessor: true,
            divergenciaQtdAulas: false,
            divergenciaQtdLocal: false,
            divergenciaQtdOfertas: false,
            horasDisponiveis: false,
            professor: ''
        }]

        let exibirAlertaDivergencias = false;
        const local = aula?.local; //critica se local está alocado, liberar quando autorizado
        const semProfessor = !aula.professores.length;
        const tipoAtividadeAula = aula.aula.tipo_de_atividade.abreviatura;
        exibirAlertaDivergencias = semProfessor;
        const divergencias = semProfessor ?
            divergenciaVazia :
            aula.professores.map(professor => {
                const { disponibilidade } = professor;
                const hora = disponibilidade && disponibilidade.find(hora => (hora.dia_da_semana == aula.horario_periodo.dia_da_semana && hora.horario_id == horario.horario_id))
                const divergenciaDisponibilidade = hora?.tipo_de_disponibilidade_id == 3 || hora?.tipo_de_disponibilidade_id == undefined;
                const semLocal = local === null ? true : false;
                const divergenciaQtdLocal = false;
                const divergenciaQtdAulas = false;
                const divergenciaQtdOfertas = false;
                const horasDisponiveis = aula.aula.duracao_minima === 3;
                const semProfessor = false;
                const divergenciaServico = (tipoAtividadeAula == 'IPRA' || tipoAtividadeAula == 'VIV') ? !aula?.servico_id : false;
                exibirAlertaDivergencias = exibirAlertaDivergencias ? exibirAlertaDivergencias : divergenciaDisponibilidade || divergenciaQtdLocal || divergenciaQtdAulas || divergenciaQtdOfertas || divergenciaServico;

                return {
                    divergenciaDisponibilidade,
                    divergenciaServico,
                    semLocal,
                    semProfessor,
                    divergenciaQtdLocal,
                    divergenciaQtdAulas,
                    divergenciaQtdOfertas,
                    horasDisponiveis,
                    professor
                }
            });

        return (
            <div className="pdf-bloco-final">
                <Row className="pdf-local-grupo">
                    <Col className='pdf-local'>{aula?.local?.nome.toUpperCase()}</Col>
                    <Col className='pdf-grupos'>{nomeAgregadorGrupo(aula)}</Col>
                </Row>
                <span className="pdf-blocoPadraoComAula__texto--comOverflow blocoPadraoComAula__texto--leve">
                    {aula?.professores.map((professor, index) =>
                        professor?.nome_social.split(" ")[0] === "Dr." || professor?.nome_social.split(" ")[0] === "DR." ||
                            professor?.nome_social.split(" ")[0] === "Dra." || professor?.nome_social.split(" ")[0] === "DRA." ||
                            professor?.nome_social.split(" ")[0] === "Prof." || professor?.nome_social.split(" ")[0] === "Prof" ||
                            professor?.nome_social.split(" ")[0] === "Profa." || professor?.nome_social.split(" ")[0] === "Profa"
                            ? professor?.nome_social.split(" ").slice(0, 2).join(" ")
                            : professor?.nome_social.split(" ")[0]
                    ).join(', ')}
                </span>
                <span className="pdf-blocoPadraoComAula__texto--comOverflow blocoPadraoComAula__texto--leve">{aula?.servico?.descricao}</span>
            </div>
        );
    }

    const periodoSemana = (semana) => {
        const minDate = new Date(Math.min(...semana));
        const maxDate = new Date(Math.max(...semana));

        const formatoData = { day: 'numeric', month: 'numeric' };
        const inicioSemana = minDate.toLocaleDateString('pt-BR', formatoData);
        const fimSemana = maxDate.toLocaleDateString('pt-BR', formatoData);

        return `${inicioSemana} a ${fimSemana}`;
    }

    return (
        <>
            {
                aulasPDF?.map((aula, indice1) => {
                    return (
                        <div className='pdf-content'>
                            <div className='pdf-header-principal'>
                                <img src={uniLogo} className="pdf-semana-padrao-file" />
                                <span className='pdf-text-semana-padrao'>Semana-Padrão / Plano de Ensino</span>
                                <span>{temas?.semestre?.descricao} Semestre {periodoLetivo?.descricao}</span>
                            </div>
                            <div className='pdf-infos-header'>
                                <span className='pdf-text-header'>Tema: {temas?.nome}</span>
                                <span className='pdf-text-header'>Semana do Tema: {indice1 + 1}</span>
                                <span className='pdf-text-header'>Semana do Semestre: {indice1 + 1}</span>
                                <span className='pdf-text-header'>Intervalo: {periodoSemana(semanasPDF[indice1])}</span>
                            </div>
                            <div className='pdf-legenda-dias'>
                                <div className='pdf-espaçamento-dias'> </div>
                                <LegendasDiaDaSemanaComDomingoESabadoCol indice={indice1} />
                            </div>
                            {
                                horariosCurso.map((horario, indice) =>
                                    <Fragment key={indice}>
                                        <div className='pdf-semana-horario'>
                                            <LegendaHorarioRow {...horario} />
                                            {horariosRow(horario, indice1)}
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    )
                })
            }
            <div className='pdf-footer'>
                <span>
                    Qualquer dúvida entrar em contato pelo email: <span className='pdf-color-email'>semanapadraomedicina01@unichristus.edu.br</span>
                </span>
                <div className='pdf-datas-footer'>
                    <span>Atualizado em {dateAndHour(dataUltimaAtualizacao)}</span>
                    <span>Download em {new Date().toLocaleDateString()} - {new Date().toLocaleTimeString()}</span>
                </div>
            </div>
            <div className='pdf-frase-final'>O cronograma poderá sofrer modificações.</div>
        </>
    )
}

export default React.memo(PDFSemanaPadrao);