import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, ModalBody } from "reactstrap";
import { NewLoader, SelectForm } from "../../Basicos/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import CardCalendario from './CardCalendario'
import CardTipoDeData from './CardTipoDeData'
import ModalCalendario from '../../Modal/ModalCalendario';
import { formatDate, formatLaravelDate, parseDate } from '../../Basicos/funcoes'
import { axiosApi } from '../../Basicos/axiosInstances';
import { stringEmDate } from '../../../services/calendarioOferta';

function CardCalendarioInstitucional({
    atualizarDadosRemotamente,
    ativar,
    ativarCofirmacao,
    estruturaModal,
    match: {
        params: {
            calendario_id
        } },
    subFuncionalidade: {
        url,
        async,
        dados,
        calendarioID,
        loader,
        descricao,
        periodos,
        campos,
        acoes,
        ...resto
    }
}) {
    const [fimModal, setFimModal] = useState(false);
    const [dataSelecionada, setDataSelecionada] = useState({});
    const [datasEstrutura, setDatasEstrutura] = useState({});
    const [loaderStatus, setLoaderStatus] = useState(loader);
    // const [periodo, setPeriodo] = useState();
    const [modalPeriodoEstaAberto, setModalPeriodoEstaAberto] = useState(true);
    const [tipoDeDatas, setTipoDeDatas] = useState([]);
    const [tipoDataselecionado, setTipoDataselecionado] = useState();
    const [pesquisa, setPesquisa] = useState({});
    const [calendario, setCalendario] = useState({});
    const [sugestao, setSugestao] = useState(false);
    const [diasLetivos, setDiasLetivos] = useState({});
    const [sugestoes, setSugestoes] = useState(false);
    const [diaContado, setDiaContado] = useState();
    const [meseContado, setMeseContado] = useState();
    const [anoContado, setAnoContado] = useState();
    const [dataSug, setDataSug] = useState();
  


    const mudarData = data => {
        const dataKey = formatDate(data, formatLaravelDate);

        setDataSelecionada({
            data,
            tipo_de_data_id: datasEstrutura[dataKey]?.tipo_de_data_id
        });
    }

    useEffect(() => {

        if (dataSelecionada?.data && tipoDataselecionado?.id) {
            salvarOnClick(tipoDataselecionado.id, dataSelecionada.data)
        }

    }, [dataSelecionada, tipoDataselecionado]);


    const calcular = async () => {


        setLoaderStatus(true);


        axiosApi({
            method: "GET",
            url: `${url}/calendario`,
            params: {
                dias: diasLetivos,
                calendario_id: calendario.id

            }
        }).then(resp => {
            setSugestoes(true)
            const { dia, mes, ano, data } = resp.data;
            setDataSug(data);
            setDiaContado(dia)
            setMeseContado(mes)
            setAnoContado(ano)

            ativar(201);
        }).catch(resp => {
            const { erro } = resp.response.data;
            ativar(400, erro);
        }).finally(() => {
            setLoaderStatus(false);
        });
    }
    const salvarSugestao = () => {
        if (dataSug) {
            salvarOnClick(2, parseDate(dataSug, formatLaravelDate))
        }
    }


    const salvarOnClick = async (tipo_de_data_id, data) => {


        setLoaderStatus(true);


        axiosApi({
            url,
            method: "POST",
            params: {
                // periodo_letivo_id: periodo.id,
                data: formatDate(data, formatLaravelDate),
                tipo_de_data_id,
                calendario_id: calendario.id
            }
        }).then(resp => {


            const { id, sucesso } = resp.data;
            const dataKey = formatDate(data, formatLaravelDate);

            const estruturas = {
                ...datasEstrutura,
                [dataKey]: {
                    id,
                    data,
                    tipo_de_data_id
                }
            };
            teste();
            setDataSelecionada({});
            setDatasEstrutura(estruturas);
            ativar(201, sucesso);
        }).catch(resp => {
            const { erro } = resp.response.data;
            ativar(400, erro);
        }).finally(() => {
            setLoaderStatus(false);
        });
    }

    const deletarConfirmacao = data =>
        ativarCofirmacao({
            titulo: 'Deletar',
            mensagem: 'Deseja deletar?',
            callback: () => deletarOnClick(data)
        });

    const deletarOnClick = data => {
        if (!data)
            return;

        const dataKey = formatDate(data, formatLaravelDate);
        const dataEstrutura = datasEstrutura[dataKey];

        if (!dataEstrutura)
            return;

        setLoaderStatus(true);

        axiosApi({
            url: `${url}/${dataEstrutura.id}`,
            method: "delete",
        }).then(resp => {
            const { sucesso } = resp.data;
            const estruturas = datasEstrutura;
            delete estruturas[dataKey];

            setDataSelecionada({});
            setDatasEstrutura(estruturas);

            ativar(201, sucesso);
        }).catch((resp) => {
            const { erro } = resp.data;
            ativar(400, erro);
        }).finally(() => {
            setLoaderStatus(false);
        });
    }

    const teste = () => {
        setLoaderStatus(true);

        axiosApi({
            url,
            method: "GET",
            params: {
                calendario_id
            }
        }).then(resp => {
            const { tipo_de_datas, datas, sucesso, calendario } = resp.data;
            setTipoDeDatas(tipo_de_datas);
            setCalendario(calendario)

            let datasEstruturas = {};

            datas.forEach(data => {
                datasEstruturas[data.data] = {
                    id: data.id,
                    data: stringEmDate(data.data),
                    tipo_de_data_id: data.tipo_de_data_id
                };
            });
            setPesquisa(datas)
            setDatasEstrutura(datasEstruturas);
            ativar(201, sucesso);
        }).catch((resp) => {
            const { erro } = resp.data;
            ativar(400, erro);
        }).finally(() => {
            setLoaderStatus(false);
        });
    }

    //Requisição inicial
    useEffect(() => {
        setLoaderStatus(true);
        atualizarDadosRemotamente();
        setLoaderStatus(false);
    }, []);

    useEffect(() => {
        // if(periodo === undefined)
        //     return;
        teste()
        
    }, []);
    return (
        <NewLoader isActive={loaderStatus} overlay >
            <CalendarioAcademico
                dataSelecionada={dataSelecionada}
                datas_estrutura={datasEstrutura}
                setTipoDataselecionado={setTipoDataselecionado}
                tipoDataselecionado={tipoDataselecionado}
                tipo_de_datas={tipoDeDatas}
                salvarOnClick={salvarOnClick}
                deletarOnClick={deletarConfirmacao}
                mudarData={mudarData}
                calendario={calendario}
                setFimModal={setFimModal}
                fimModal={fimModal}
                setSugestao={setSugestao}
                sugestao={sugestao}
                diasLetivos={diasLetivos}
                setDiasLetivos={setDiasLetivos}
                calcular={calcular}
                setDiaContado={setDiaContado}
                sugestoes={sugestoes}
                setSugestoes={setSugestoes}
                diaContado={diaContado}
                mesContado={meseContado}
                anoContado={anoContado}
                salvarsugestao={salvarSugestao}
            />
        </NewLoader>
    );
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        atualizarDadosRemotamente: () => dispatch(actionsRedux.atualizarSubFuncionalidade(ownProps.funcionalidade, ownProps.indiceSubFuncionalidade)),
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardCalendarioInstitucional);

function CalendarioAcademico({
    dataSelecionada, datas_estrutura, tipo_de_datas, salvarOnClick, mudarData, periodo, deletarOnClick, setTipoDataselecionado, tipoDataselecionado, calendario,
    setFimModal, fimModal, setSugestao, sugestao, diasLetivos, setDiasLetivos, calcular, setSugestoes, sugestoes, diaContado, mesContado, anoContado, salvarsugestao, semestre
}) {

    return (
        <Row className="calendario-academico">
            <Col xs={12} sm={12} md={4} lg={4} xl={4} >
                <Row>
                    <Col sm={12} md={12}>
                        <CardTipoDeData
                            calendario={calendario}
                            semestre={calendario.semestre}
                            semestres ={semestre}
                        />
                    </Col>
                    <Col sm={12} md={12} className="mb-3">
                        <CardCalendario
                            selectedDays={dataSelecionada.data}
                            datasEstrutura={datas_estrutura}
                            tipoDeDatas={tipo_de_datas}
                            legenda

                        />
                    </Col>
                </Row>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8} xl={8} >
                <CardCalendario
                    className="h-100"
                    salvarOnClick={salvarOnClick}
                    selectedDays={dataSelecionada.data}
                    datasEstrutura={datas_estrutura}
                    tipoDeDatas={tipo_de_datas}
                    onDayClick={mudarData}
                    deletarOnClick={deletarOnClick}
                    setTipoDataselecionado={setTipoDataselecionado}
                    tipoDataselecionado={tipoDataselecionado}
                    setFimModal={setFimModal}
                    fimModal={fimModal}
                    setSugestao={setSugestao}
                    sugestao={sugestao}
                    setDiasLetivos={setDiasLetivos}
                    diasLetivos={diasLetivos}
                    calcular={calcular}
                    setSugestoes={setSugestoes}
                    sugestoes={sugestoes}
                    diaContado={diaContado}
                    mesContado={mesContado}
                    anoContado={anoContado}
                    salvarsugestao={salvarsugestao}
                    button_day
                />
            </Col>
        </Row>
    );
}
