import React from 'react'
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-virtualized/styles.css';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer'
import { List } from 'react-virtualized/dist/commonjs/List'
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized/dist/commonjs/CellMeasurer'
import { Col, Row } from 'reactstrap';

/**
 * * é igual a feito
 * Usa a mesma estrutura do DragDrop porém com pequenas modificações para o rotaForm
 * Tiver tempo deve ser unificado entre o DragDrop e o DragDropT
 * Tratar ...props no reactClone
 * style e className fildset
 * row 100% lgo className tambem
 * getStyle tem que tirar a margem se não o border não fica correto
 * Item className
 * itens deve virar Disponiveis(deve ser repassado aos itens solucao e checklist)
 * onChangeState para onChangeLista
 * controle do tamanhos da col via props *
 * separar os props dos itens como um attributo por exemplo itemProps( olhar o de baixo )
 * para renderizar o item fazer algo semelhante ao rowRenderer ou deixar itemProps( olhar o de cima )
 */

// Função para reodernar a lista
const reodernar = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

//Funcao para mover um item entre dois arrays
const mover = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    result['removido'] = removed;

    return result;
};

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
    // No componente original será melhor usar um flag ou entao passar logo para className
    return {
        ...virtualStyle,
        ...draggableStyle
    };
}

const rowRenderer = (listaID, props, cache) => ({ index, style, parent }) => {
    const items = props[listaID];

    if (!items || !items[index]) {
        return null;
    }

    const item = items[index];
    return (
        <CellMeasurer cache={cache} columnIndex={0} key={item[props.campoID]} parent={parent} rowIndex={index}>
            <Draggable draggableId={String(item[props.campoID])} index={index}>
                {provided => (
                    <Item 
                        itemComponent={props.itemComponent}
                        itensProps={props.itensProps}
                        provided={provided}
                        item={item}
                        index={index}
                        style={style}
                        listaID={listaID}
                    />
                )}
            </Draggable>
        </CellMeasurer>
    );
};

const limparCacheDosItens = ({ cacheDisponiveis, cacheSelecionados }) => {
    cacheDisponiveis.clearAll();
    cacheSelecionados.clearAll();
}

const Item = ({ provided, index, item, style, isDragging, listaID, itensProps, itemComponent }) => {
    return (
        <div
            className={itensProps.wrapClassName}
            ref={provided.innerRef}
            data-indice={index + 1}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({
                draggableStyle: provided.draggableProps.style,
                virtualStyle: style,
                isDragging
            })}
        >
            {React.cloneElement(itemComponent, {
                indice: index,
                listaID: listaID,
                dados: item,
                ...itensProps
            })}
        </div>
    )
};

const Lista = (props) => {

    const listCache = React.useRef({
        cacheDisponiveis: new CellMeasurerCache({ fixedWidth: true, defaultHeight: 100 }),
        cacheSelecionados: new CellMeasurerCache({ fixedWidth: true, defaultHeight: 100 }),
    });

    const {
        cacheDisponiveis,
        cacheSelecionados,
    } = listCache.current;

    const {
        disponiveisCol = { xs: 12, sm: 12, md: props.isMultiList ? 6 : 12 },
        selecionadosCol = { xs: 12, sm: 12, md: 6 },
        onChangeLista,
        isMultiList,
        campoID,
        noGutters,
        legendaDisponiveis,
        legendaSelecionados,
        disponiveis,
        selecionados,
        divListClassName = "fieldset-lista",
    } = props;

    limparCacheDosItens(listCache.current);

    const getList = id => props[id];

    const onDragEnd = result => {
        const { source, destination } = result;
        let state = {};
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const itens = reodernar(
                getList(source.droppableId),
                source.index,
                destination.index
            );
            if (source.droppableId === 'disponiveis'){
                state = { disponiveis: itens };
            }
                
            if (source.droppableId === 'selecionados') {
                state = { selecionados: itens };
            }
            onChangeLista(state);

        } else {
            const result = mover(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            onChangeLista({
                disponiveis: (result.disponiveis) ? result.disponiveis : [],
                selecionados: (result.selecionados) ? result.selecionados : [],
                removido: result.removido
            });
        }
    };

    const renderClone = (provided, snapshot, rubric) => {
        const item = getList(rubric.source.droppableId)[rubric.source.index];
        return (
            <Item
                {...props}
                item={item}
                provided={provided}
                listaID={campoID}
                index={rubric.source.index}
                key={item[campoID]}
            />
        );
    };

    return (
        <Row noGutters={noGutters} className={`${props.sequenciaAula ? "" : "h-100"}`}>
            <DragDropContext onDragEnd={onDragEnd} >
                <Col {...disponiveisCol}>
                    {legendaDisponiveis??''}
                    <Droppable droppableId="disponiveis" mode="virtual" renderClone={renderClone}>
                        {(provided, snapshot) => {
                            const disponiveisCount = snapshot.isUsingPlaceholder ? disponiveis.length + 1 : disponiveis.length;
                            return (
                                <div className={divListClassName}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                width={width}
                                                height={height}
                                                rowCount={disponiveisCount}
                                                rowHeight={cacheDisponiveis.rowHeight}
                                                deferredMeasurementCache={cacheDisponiveis}
                                                rowRenderer={rowRenderer('disponiveis', props, cacheDisponiveis)}
                                                className={"list-drag-n-drop"}
                                                ref={ref => {
                                                    // react-virtualized has no way to get the list's ref that I can so
                                                    // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                                                    if (ref) {
                                                        // eslint-disable-next-line react/no-find-dom-node
                                                        const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                                                        if (whatHasMyLifeComeTo instanceof HTMLElement) {
                                                            provided.innerRef(whatHasMyLifeComeTo);
                                                        }
                                                    }
                                                }} 
                                            />
                                        )}
                                    </AutoSizer>
                                </div>
                            );
                        }}
                    </Droppable>
                </Col>
                {isMultiList && (
                    <Col {...selecionadosCol} >
                        {legendaSelecionados}
                        <Droppable droppableId="selecionados" mode="virtual" renderClone={renderClone}>
                            {(provided, snapshot) => {
                                const selectCount = snapshot.isUsingPlaceholder ? selecionados.length + 1 : selecionados.length;
                                return (
                                    <div className={divListClassName}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    width={width}
                                                    height={height}
                                                    rowCount={selectCount}
                                                    rowHeight={cacheSelecionados.rowHeight}
                                                    deferredMeasurementCache={cacheSelecionados}
                                                    rowRenderer={rowRenderer('selecionados', props, cacheSelecionados)}
                                                    className={"list-drag-n-drop"}
                                                    ref={ref => {
                                                        // react-virtualized has no way to get the list's ref that I can so
                                                        // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                                                        if (ref) {
                                                            // eslint-disable-next-line react/no-find-dom-node
                                                            const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                                                            if (whatHasMyLifeComeTo instanceof HTMLElement) {
                                                                provided.innerRef(whatHasMyLifeComeTo);
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                        </AutoSizer>
                                    </div>
                                );
                            }}
                        </Droppable>
                    </Col>
                )}
            </DragDropContext>
        </Row>
    );
}

export default React.memo(Lista);