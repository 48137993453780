import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { MenuDasFuncionalidades } from '../Menu';
import { Route } from 'react-router-dom';
import GeraOfertaPeriodoLetivo from './GeraOfertaPeriodoLetivo';
import CalendarioOfertaPeriodoLetivo from './CalendarioOfertaPeriodoLetivo';


function OfertaPorPeriodoLetivo({
    props,
    subFuncionalidade,
    indiceSubFuncionalidade
}) {

    return (
        <Fragment>
            <MenuDasFuncionalidades
                funcionalidade={"calendario-academico"}
                classNavBar="navbar-consultas" 
                titulo="Cadastro de Ofertas"

            />
            <Col sm={12} md={12} className="col-conteudo semana-padrao">
                <Route
                    exact
                    path={subFuncionalidade.url}
                    render={ props => 
                        <GeraOfertaPeriodoLetivo
                            {...props}
                            subFuncionalidade={subFuncionalidade}
                            indiceSubFuncionalidade={indiceSubFuncionalidade}
                            funcionalidade={"auxiliares"}
                        />
                    }
                />

                <Route
                    exact
                    path={`${subFuncionalidade.url}/semestre/:semestre_id/tema/:tema_id/periodo-letivo/:periodo_letivo_id/turma/:turma_id`}
                    render={ props => 
                        <CalendarioOfertaPeriodoLetivo
                            {...props}
                            subFuncionalidade={subFuncionalidade}
                            indiceSubFuncionalidade={indiceSubFuncionalidade}
                            funcionalidade={"auxiliares"}
                        />
                    }
                />
            </Col>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        atualizar: (dados) => dispatch(actionsRedux.atualizarDadosEmpresa(dados))
    }
}

const mapStateToProps = (state) => {
    return {
        //empresas: state.empresas.empresas,
        //solucao: state.empresas.solucao,
        //alterar: state.empresas.alterar
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfertaPorPeriodoLetivo)