import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import './styles.css';
import { Col } from 'reactstrap';

const CardAulasDND = ({ aulas, tiposAtividade, horarioSelecionado, horariosOfertas }) => {
    let aulasAlocadas = [];
    horariosOfertas.forEach(oferta => oferta.horarios_aulas.forEach(horario => {
        if (!aulasAlocadas.includes(horario.aulas_id)) {
            aulasAlocadas.push(horario.aulas_id)
        }
    }));
    const aulasSorted = aulas.filter(aula => !aulasAlocadas.includes(aula.id) && (aula.tipo_de_atividade_id === horarioSelecionado.tipo_atividade_id || undefined === horarioSelecionado.tipo_atividade_id));
    aulasSorted.sort(function (a, b) {
        return a.tipo_de_atividade_id - b.tipo_de_atividade_id
    })
    return (
        <>
            {aulasSorted.length == 0 ? '' :
                <Col xs={6} sm={4} md={3} lg={2} xl={2} >
                    <div className='card-dnd-title align-content-middle'>
                        AULAS
                    </div>
                    <Droppable droppableId='aulas-dnd'>
                        {(provided, snapshot) => (
                            <div className='card-dnd-aulas'
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    aulasSorted.map((aula, index) => {
                                        const tipoAtividade = tiposAtividade.find(el => el.id === aula.tipo_de_atividade_id)
                                        return (
                                            <Draggable key={aula.id} draggableId={`aula${aula.id}`} index={index}>
                                                {(provided, snapshot) => {
                                                    const style = {
                                                        backgroundColor: tipoAtividade?.cor,
                                                        ...provided.draggableProps.style
                                                    };
                                                    return (
                                                        <div className='bloco-dnd-aula'
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={style}
                                                        >
                                                            <span className='atividade'>{tipoAtividade?.nome}</span>
                                                            <br />
                                                            <span className='nome'>{aula.titulo}</span>
                                                        </div >
                                                    )
                                                }}
                                            </Draggable>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </Droppable>
                </Col>
            }
        </>
    )

}

export default CardAulasDND