import React from 'react'
import { Row, Button, Modal, ModalBody } from "reactstrap";

const ModalPublicado = ({ isOpen, dados, toggleModalPublicado, handleSubmitPublicado }) => {

    return (
        <Modal className="custom-modal-size-publicado" isOpen={isOpen}>
            <ModalBody>
                <Row className={"text-modal-publicado"}>
                    <h3>Deseja realmente publicar a Semana Padrão</h3>
                    <h3>{dados.periodoLetivo} - {dados.semestre} - {dados.tema} {dados.turma != null ? `- ${dados.turma}` : '' } ?</h3>
                </Row>

                <Row className={"buttons-modal-publicado"}>
                    <Button onClick={() => toggleModalPublicado(null, null)} className="btn-transparente">Cancelar</Button>
                    <Button onClick={handleSubmitPublicado} color="primary"> Confimar </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalPublicado