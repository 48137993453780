import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardCrud, CardCalendarios, CardCalendarioInstitucional } from '../Conteudo/Cards/index';
import { convertToSlug } from '../Basicos/funcoes';
import CardCrudFeriado from '../Conteudo/Cards/CardCrudFeriado';

function Auxiliares({ auxiliares, }) {
    return (
        <Row noGutters={true}>
            <MenuDasFuncionalidades
                funcionalidade={"Auxiliares"}
                classNavBar="navbar-consultas"
                titulo="AUXILIARES"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Switch>
                    {auxiliares.map((subFuncionalidade, indice) => {
                        switch (convertToSlug(subFuncionalidade.descricao)) {
                            case "calendario-academico":
                                const rotas = []
                                rotas.push(
                                    <Route
                                        exact
                                        path={`${subFuncionalidade.url}/calendario/:calendario_id`}
                                        render={(props) => (
                                            <CardCalendarioInstitucional
                                                {...props}
                                                subFuncionalidade={subFuncionalidade}
                                                indiceSubFuncionalidade={indice}
                                                funcionalidade={"auxiliares"}
                                            />
                                        )}
                                    />
                                )

                                rotas.push(
                                    <Route
                                        exact
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <CardCalendarios
                                            {...props}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"auxiliares"}
                                        />
                                        }
                                    />
                                )
                                return rotas;

                            case "feriados":
                                return <Route
                                    key={subFuncionalidade.url}
                                    path={subFuncionalidade.url}
                                    render={(props) => <CardCrudFeriado
                                        {...props}
                                        subFuncionalidade={subFuncionalidade}
                                        indiceSubFuncionalidade={indice}
                                        funcionalidade={"auxiliares"}
                                    />
                                    }
                                />;

                            // case "servicos":
                            //     return <Route
                            //         key={subFuncionalidade.url}
                            //         path={subFuncionalidade.url}
                            //         render={(props) => <CardCrudServicos
                            //             {...props}
                            //             subFuncionalidade={subFuncionalidade}
                            //             indiceSubFuncionalidade={indice}
                            //             funcionalidade={"auxiliares"}
                            //         />
                            //         }
                            //     />;

                            default:
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <CardCrud
                                            {...props}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"auxiliares"}
                                        />
                                        }
                                    />
                                );
                        }
                    })}
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        auxiliares: state.subFuncionalidades.auxiliares,
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auxiliares)