import React, { Fragment, useState, useRef } from 'react';
import { Estruturas, Loader } from "../index";
import { Col, Row } from "reactstrap";
import { MenuTable } from '../../Menu';
import { iniciarValorPadrao, create_UUID } from '../funcoes';
import { axiosApi } from '../axiosInstances';
import ModalAtualizarAula from '../../Modal/ModalAtualizarAula';
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';

const GerarEstrutura = ({
    iconAdicionar,
    iconRemover,
    iconSalvar,
    iconRemoverLocal,
    estruturaPadrao,
    valorPadrao,
    submit,
    formularioGeral,
    adicionarOnClick,
    salvarLocal,
    removerLocal,
    removerOnClick,
    onChangeEstrutura,
}) => {
    return (
        <Fragment>
            <Col xs={9} sm={10} md={10} lg={11} xl={11}>
                <Row>
                    <Estruturas
                        estrutura={iniciarValorPadrao(estruturaPadrao, valorPadrao)}
                        submit={submit}
                        formularioGeral={formularioGeral}
                        onChange={onChangeEstrutura}
                    />
                </Row>
            </Col>
            <Col xs={3} sm={2} md={2} lg={1} xl={1}>
                <MenuTable
                    iconRemover2={iconRemover}
                    iconAdicionar={iconAdicionar}
                    iconSalvar={iconSalvar}
                    iconRemoverLocal={iconRemoverLocal}
                    removerOnClick={removerOnClick}
                    adicionarOnClick={adicionarOnClick}
                    salvarLocal={salvarLocal}
                    removerLocal={removerLocal}
                    containerStyle={{ height: '100%' }}
                />
            </Col>
        </Fragment>
    );
};

const ReplicarForm = ({
    ativar,
    name,
    estruturaPadrao,
    submit,
    onChange,
    valorAtual,
    formularioGeral,
    valorPadrao = [{}]
}) => {
    const [quantidadeDeEstrutura, setQuantidadeDeEstrutura] = useState(valorPadrao.length === 0 ? 1 : valorPadrao.length);
    const formulario = useRef(valorPadrao.length === 0 ? [{}] : valorPadrao);
    const estruturas = [];
    const [modalAtualizacao, setModalAtualizacao] = useState(false);
    const [indiceModal, setIndiceModal] = useState();
    const [loader, setLoader] = useState(false);

    const adicionarOnClick = () => {
        formulario.current.push({});
        setQuantidadeDeEstrutura(quantidadeDeEstrutura + 1);
    }

    const removerOnClick = (indice) => {
        formulario.current.splice(indice, 1);
        setQuantidadeDeEstrutura(quantidadeDeEstrutura - 1);

        if (valorAtual) {
            onChange(name, formulario.current.slice());
        }
    };

    const removerOnClickLocal = async (indice) => {
        try {
            setLoader(true);
            await axiosApi.delete(`/auxiliares/local-semestre?semestre_id=${formulario.current[indice].semestre_id}&tipo_atividade_id=${formulario.current[indice].tipo_atividade_id}&turma=${formulario.current[indice].turma}`, formulario.current[indice]);
            adicionarOnClick();
            ativar(201, 'Local removido com sucesso.')
            removerOnClick(indice);
        } catch (error) {
            ativar(400, 'Algo deu errado.')
        } finally {
            setLoader(false);
            setModalAtualizacao(false);
        }
    }

    const salvarLocal = (indice) => {
        setLoader(true);
        setIndiceModal(indice)
        setModalAtualizacao(true);
    }

    const atualizarAula = async (atualizaHoje) => {
        try {
            var dados = {
                ...formulario.current[indiceModal],
                abreviatura_curso: formularioGeral.abreviatura,
                atualiza_hoje: atualizaHoje
            }
            await axiosApi.post(`/auxiliares/local-semestre`, dados);
            ativar(201, 'Local adicionado com sucesso.')
            adicionarOnClick();
        } catch (error) {
            console.log(error.response.data.error)
            ativar(400, error.response.data.error)
        } finally {
            setModalAtualizacao(false);
            setLoader(false);
        }
    }

    const retornarKeyEstrutura = (indice) => {
        formulario.current[indice].componentId = formulario.current[indice].componentId ? formulario.current[indice].componentId : create_UUID();
        return formulario.current[indice].componentId;
    };

    const onChangeEstrutura = (formularioEstrutura, formularioIncompleto, indice, invalidar) => {
        if (formularioIncompleto) {
            onChange(name, "");
            // dispatch();
        } else {
            formulario.current[indice] = { componentId: formulario.current[indice].componentId, ...formularioEstrutura };
            onChange(name, formulario.current.slice());
        }

        //TODO: Algoritmo para invalidar aqui
        if (formularioEstrutura && formularioEstrutura['inicio'] === '12:22') {
            invalidar('dia_da_semana')
            invalidar('inicio')
        }
    };

    for (let indice = 0; indice < quantidadeDeEstrutura; indice++) {
        let iconAdicionar = quantidadeDeEstrutura === indice + 1;
        let iconRemover = quantidadeDeEstrutura !== indice - 1;
        estruturas.push(
            <GerarEstrutura
                estruturaPadrao={estruturaPadrao}
                valorPadrao={formulario.current[indice]}
                submit={submit}
                indice={indice}
                iconRemover={name == "locais" ? false : iconRemover}
                iconRemoverLocal={name == "locais" ? true : false}
                iconAdicionar={iconAdicionar}
                iconSalvar={name == "locais" ? true : false}
                removerOnClick={() => removerOnClick(indice)}
                adicionarOnClick={adicionarOnClick}
                salvarLocal={() => salvarLocal(indice)}
                removerLocal={() => removerOnClickLocal(indice)}
                formularioGeral={formularioGeral}
                onChangeEstrutura={(formularioEstrutura, incompleto, formularioIncompleto, invalidar) => onChangeEstrutura(formularioEstrutura, formularioIncompleto, indice, invalidar)}
                key={retornarKeyEstrutura(indice)}
            />
        );
    };

    return (
        <>
            {loader && <Loader overlay={true} />}
            <ModalAtualizarAula isOpen={modalAtualizacao} atualizarAula={atualizarAula} />
            <Col sm={12}>
                <Row>{estruturas}</Row>
            </Col>
        </>
    );
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
    }
}
export default connect(null, mapDispatchToProps)(ReplicarForm)