import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { MenuDasFuncionalidades } from "../Menu";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { ChaveamentoDeModal } from "../Modal";
import { CardImport } from '../Conteudo/Cards';
import { axiosApi } from '../Basicos/axiosInstances';
import { useEffect } from 'react';

function Import({
    importador,
    estadoModal,
    desativarModal,
    adicionar,
    editar,
    estruturaModal,
    ativarAlert
}) {
    const [downloadsPermitidos, setDownloadsPermitidos] = useState([]);
    const { componentesEsquerda, componentesDireita } = gerarCards(importador, downloadsPermitidos);

    const getImportacaoFuncionalidadesRoles = async () => {
        try {
            const response = await axiosApi.get('importador/allow-download');
            setDownloadsPermitidos(response.data.dados);
        } catch (error) {
            console.log(error)
        }
    }

    const getFuncionalidades = async () => {
        try {
            const response = await axiosApi.get('funcionalidades/update');
            const { funcionalidades } = response.data;
            const funcionalidadesLocal = localStorage.getItem('funcionalidades_uni');

            if (funcionalidades && JSON.stringify(funcionalidades) !== funcionalidadesLocal) {
                localStorage.setItem('funcionalidades_uni', JSON.stringify(funcionalidades));
                window.location.reload();
            }
            if (funcionalidades && funcionalidades[11]?.subfuncionalidades?.length === 0) {
                ativarAlert(400, "Acesso não autorizado! Entre em contato com o Administrador da Plataforma");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getFuncionalidades();
        getImportacaoFuncionalidadesRoles();
    }, []);

    return (
        <Row noGutters>
            <MenuDasFuncionalidades
                funcionalidade={"importador"}
                titulo="Importador"
            />

            <Col sm={12} md={12} className="col-conteudo">
                <Row>
                    <Col sm={12} md={12} lg={6}>{componentesEsquerda}</Col>
                    <Col sm={12} md={12} lg={6}>{componentesDireita}</Col>
                </Row>
                <ChaveamentoDeModal
                    boolModal={estadoModal}
                    toggle={desativarModal}
                    adicionar={adicionar}
                    editar={editar}
                    estrutura={estruturaModal}
                />
            </Col>
        </Row>
    )
}

const gerarCards = (importador, downloadsPermitidos) => {
    const componentesEsquerda = [];
    const componentesDireita = [];
    importador.forEach((subfuncionalidade, indice) => {
        if (indice % 2 === 0) {
            componentesEsquerda.push(
                <CardImport
                    key={indice}
                    indice={indice}
                    className="cards-menores"
                    nomeButton={"Importar"}
                    funcionalidade={'importador'}
                    downloadsPermitidos={downloadsPermitidos}
                    url={subfuncionalidade.url}
                    collapse
                    header
                    post
                />
            );
        } else {
            componentesDireita.push(
                <CardImport
                    key={indice}
                    indice={indice}
                    className="cards-menores"
                    nomeButton={"Importar"}
                    funcionalidade={'importador'}
                    downloadsPermitidos={downloadsPermitidos}
                    url={subfuncionalidade.url}
                    collapse
                    header
                    post
                />
            );
        }
    });

    return { componentesEsquerda, componentesDireita };
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editar: state.subFuncionalidades.editar,
        importador: state.subFuncionalidades.importador
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import);
