import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardCrud } from '../Conteudo/Cards/index';
import { convertToSlug } from '../Basicos/funcoes';

function Aulas({ aulas }) {

    return (
        <Row noGutters={true}>
            <MenuDasFuncionalidades
                funcionalidade={"Aulas"}
                classNavBar="navbar-consultas"
                titulo="Aulas"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Switch>
                    {aulas.map((subFuncionalidade, indice) => {
                        switch (convertToSlug(subFuncionalidade.descricao)) {
                            case "lista-de-alunos":
                                return null;
                            default:
                                return (
                                    <Route
                                        key={subFuncionalidade.url}
                                        path={subFuncionalidade.url}
                                        render={(props) => <CardCrud
                                            {...props}
                                            filtro={true}
                                            pesquisaGlobal={false}
                                            subFuncionalidade={subFuncionalidade}
                                            indiceSubFuncionalidade={indice}
                                            funcionalidade={"aulas"}
                                        />
                                        }
                                    />
                                );
                        }
                    })}
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    // console.log(state.subFuncionalidades)
    return {
        aulas: state.subFuncionalidades.aulas,
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aulas)