import React from 'react'
import { Col, Button } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";

function CardInfoCalendarioPadrao({ informacoesDoCalendario: { tema, periodo, turma }, setDetatelhes }) {
    return (
        <Col>
            {tema && (
                <div style={{ margin: "0rem 0rem" }} className="info-calendario-padrao">
                    <fieldset>
                        <legend>{tema.curso.nome} / {periodo.descricao} / SEMESTRE: {tema.semestre.descricao} {turma != null ? `/ ${turma.descricao}` : ''} </legend>
                    </fieldset>
                    <fieldset>
                        <legend>{tema.nome}</legend>
                    </fieldset>
                </div>
            )}
            <div className="align-content-middle mt-2">
                <Button onClick={() => setDetatelhes(true)} className="float-right btn-transparente">Detalhes</Button>
            </div>

        </Col>
    );
}

export default CardHoc(CardInfoCalendarioPadrao);