import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const LinhaAluno = ({ row, prepareRow }) => {
    return (
        <Fragment>
            <div style={{ marginTop: '1rem' }} />
            <tr key={`aluno`}>
                <td colSpan={row.cells.length} className="p-0">
                    <table className="table table-bordered m-0" style={{ borderWidth: '4px', borderColor: '#19385D' }}>
                        <tr {...row.getRowProps()} className="align-middle">
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps({
                                    className: `${cell.column.className} h6`,
                                    style: { ...cell.column.style },
                                    default: "N/A"
                                })}
                                >
                                    {cell.value || cell.column.id === "acoes" || cell.column.id === "Cor" || cell.column.id === "Grupo 1" || cell.column.id === "Grupo 2" || cell.column.id === "Professor"
                                        ? <strong>{cell.render('Cell')}</strong>
                                        : "N/A"}
                                </td>
                            ))}
                        </tr>
                    </table>
                </td>
            </tr>
            {row.original.servicos && row.original.servicos.map((servico, index) => (
                <tr key={`${row.original.matricula}-${index}`} >
                    <td colSpan={row.cells.length} className="p-0">
                        <table className="table table-bordered m-0" style={{ borderWidth: '4px', borderColor: '#19385D' }}>
                            <thead style={{ background: 'var(--cor-principal)', color: 'white', textAlign: 'center' }}>
                                <tr>
                                    <th style={{ width: '150px' }}>Serviço</th>
                                    <th style={{ width: '150px' }}>Quantidade Rodízio</th>
                                    <th style={{ width: '150px' }}>Data</th>
                                    <th style={{ width: '150px' }}>Início</th>
                                    <th style={{ width: '150px' }}>Local</th>
                                    <th style={{ width: '150px' }}>Professor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {servico.rodizios.map((rodizio, rodIndex) => (
                                    <tr key={rodIndex}>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{servico.nome}</td>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{servico.qtde_rodizio}</td>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{rodizio.data}</td>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{rodizio.inicio}</td>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{rodizio.local}</td>
                                        <td style={{ width: '150px', textAlign: 'center' }}>{rodizio.professor}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </td>
                </tr>
            ))}
            <div style={{ marginBottom: '1rem' }} />
        </Fragment>
    );
};

export default LinhaAluno;
