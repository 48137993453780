import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Button } from "reactstrap";
import { Estruturas, Loader, NewLoader } from "../../Basicos";
import CardHoc from "../../Hoc/cardHoc";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import { axiosApi } from '../../Basicos/axiosInstances';
import { jsonToFormData } from '../../Basicos/funcoes';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalError = ({ isOpen, setIsOpen, className, relatorio }) => {

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>Relatório de Erros</ModalHeader>
            <ModalBody>
                <embed type="text/plain"
                    src={relatorio}
                    width="100%"
                    height="600px"
                />
                <Button href={relatorio} target="_blank" download>Download</Button>
                <Button onClick={toggle}>Fechar</Button>
            </ModalBody>
        </Modal>
    );
}

function CardConsulta(props) {
    const requisicao = useRef(false);
    const formulario = useRef(null);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [relatorio, setRelatorio] = useState('');
    const [linkXLS, setLinkXLS] = useState('');
    const [fileName, setFileName] = useState('');
    const atualizarFiltroRemotamente = useCallback(props.atualizarFiltroRemotamente, []);
    const hiddenButtonDownload = props.downloadsPermitidos.filter((item) => { return item.funcionalidade.url === (props.url).replace('/importador', '') })[0];

    useEffect(() => {
        if (!requisicao.current && props.collapse) {
            atualizarFiltroRemotamente();
            ModeloPlanilha();
            requisicao.current = true;
        }
    }, [props.collapse, atualizarFiltroRemotamente]);

    const consultar = () => {
        if (!formulario.current.state.incompleto) {
            let params = Object.assign({}, formulario.current.formulario)
            props.gerarConsulta(params);
        }
        setSubmit(true)
    }

    const onSubmit = async () => {
        if (!formulario.current.state.incompleto) {
            let params = Object.assign({}, formulario.current.formulario)
            setLoading(true);
            try {
                const response = await axiosApi({
                    method: 'POST',
                    url: props.url,
                    headers: { 'Content-Type': 'multipart/form-data' },
                    data: jsonToFormData({ file: params.file[0] }, 'POST')
                });
                if (response.data.sucesso) {
                    props.alert(201, 'Importado com Sucesso')
                    setLoading(false);
                }
                if (response.data.error) {
                    props.alert(400, response.data.error)
                    setRelatorio(response.data.relatorio)
                    setOpenModal(true)
                    setLoading(false)
                }
            } catch (error) {
                if (error?.response?.data?.error) {
                    props.alert(400, error.response.data.error)
                }
                else {
                    if (error.response.status === 403) {
                        props.alert(400, error?.response?.data?.mensagem);
                    } else {
                        props.alert(400, error.message)

                    }
                }
                setLoading(false);
            }
        }
    }

    const ModeloPlanilha = () => {
        switch (props.url) {
            case '/importador/importar-temas':
                setLinkXLS('storage/planilha_modelos/1_Modelo_Temas&.xlsx')
                setFileName('1_Modelo_Temas&.xlsx')
                break;
            case '/importador/importar-professor':
                setLinkXLS('storage/planilha_modelos/2_Modelo_Professores_com_Disponibilidade&.xlsx')
                setFileName('2_Modelo_Professores_com_Disponibilidade&.xlsx')
                break;
            case '/importador/importar-aulas':
                setLinkXLS('storage/planilha_modelos/3_Modelo_Aulas&.xlsx')
                setFileName('3_Modelo_Aulas&.xlsx')
                break;
            case '/importador/importar-alunos':
                setLinkXLS('storage/planilha_modelos/4_Modelo_Alunos&.xlsx')
                setFileName('4_Modelo_Alunos&.xlsx')
                break;
            case '/importador/importar-matricula-por-aluno':
                setLinkXLS('storage/planilha_modelos/5_Modelo_Matriculas_por_Semestre&.xlsx')
                setFileName('5_Modelo_Matriculas_por_Semestre&.xlsx')
                break;
            case '/importador/importar-aulas-praticas':
                setLinkXLS('storage/planilha_modelos/6_Modelo_Aulas_Praticas&.xlsx')
                setFileName('6_Modelo_Aulas_Praticas&.xlsx')
                break;
            default:
                break;
        }
    }

    return (
        <Col xs={12} sm={12} >
            <ModalError isOpen={openModal} setIsOpen={setOpenModal} relatorio={relatorio} />
            {props.estrutura ? (
                <NewLoader overlay={props.loader} isActive={loading}>
                    <Fragment>
                        <Estruturas
                            estrutura={props.estrutura}
                            tipo={"forms"}
                            ref={formulario}
                            submit={submit}
                        />
                        <Button
                            className="float-right margin-btnConsulta"
                            onClick={props.post ? onSubmit : consultar}
                            size="sm"
                        >
                            {props.nomeButton}
                        </Button>
                        <Button
                            className={`float-left margin-btnConsulta ${!hiddenButtonDownload?.download_template ? "hidden" : ""}`}
                            size="sm"
                            href={linkXLS}
                            target="_blank"
                            download
                        >
                            Baixar Modelo
                        </Button>
                    </Fragment>
                </NewLoader>
            ) : (
                <Loader overlay={props.loader} padding />
            )}
        </Col>
    )
}

CardConsulta.propTypes = {
    titulo: PropTypes.string,
    className: PropTypes.string.isRequired,
    nomeButton: PropTypes.string.isRequired,
    estrutura: PropTypes.array
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const consulta = ownProps.funcionalidade !== 'exportador' ? (params) => dispatch(actionsRedux.ativarModalSubFuncionalidade(ownProps.funcionalidade, ownProps.indice, 'relatorio', null, params)) : (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params));
    return {
        gerarConsulta: consulta,
        atualizarFiltroRemotamente: () => dispatch(actionsRedux.buscarFiltrosSubFuncionalidade(ownProps.funcionalidade, ownProps.indice)),
        exportar: (params) => dispatch(actionsRedux.exportarSubfuncionalidade(ownProps.funcionalidade, ownProps.indice, params)),
        alert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

const mapStateToProps = (state, ownProps) => {
    const subFuncionalidade = state.subFuncionalidades[ownProps.funcionalidade][ownProps.indice];
    return {
        tituloHeader: subFuncionalidade.descricao,
        estrutura: subFuncionalidade.estrutura,
    }
}

var consulta = CardHoc(React.memo(CardConsulta))
export default connect(mapStateToProps, mapDispatchToProps)(consulta)
