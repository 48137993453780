import React from 'react'
import { Row, Col } from 'reactstrap';
import { Redirect, Switch, Route } from "react-router-dom";
import { MenuDasFuncionalidades } from "../Menu/index";
import { connect } from 'react-redux';
import * as actionsRedux from '../Store/Actions/index';
import { CardCrud } from '../Conteudo/Cards/index';

function Mensagens({ mensagens }) {
    return (
        <Row noGutters={true}>
            <MenuDasFuncionalidades
                funcionalidade={"Mensagens"}
                classNavBar="navbar-consultas"
                titulo="Mensagens"
            />
            <Col sm={12} md={12} className="col-conteudo">
                <Switch>
                    {
                        mensagens.map((subFuncionalidade, indice) => {
                            return <>
                                <Route
                                    key={subFuncionalidade.url}
                                    path={subFuncionalidade.url}
                                    render={(props) => <CardCrud
                                        {...props}
                                        subFuncionalidade={subFuncionalidade}
                                        indiceSubFuncionalidade={indice}
                                        funcionalidade={"mensagens"}
                                    />
                                    }
                                />
                            </>
                        })
                    }
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
            </Col>
        </Row>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        desativarModal: () => dispatch(actionsRedux.desativarModalSubFuncionalidade()),
    }
}

const mapStateToProps = (state) => {
    return {
        mensagens: state.subFuncionalidades["mensagens"],
        estruturaModal: state.subFuncionalidades.estruturaModal,
        estadoModal: state.subFuncionalidades.estadoModal,
        adicionar: state.subFuncionalidades.adicionar,
        editarFunc: state.subFuncionalidades.editar,
        tipoAcao: state.subFuncionalidades.tipoAcao,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mensagens)