import React, { Fragment, useEffect, useState } from 'react';
import { Col, Button, Form } from "reactstrap";
import { Tabela, InputForm, DateForm, NewLoader } from "../../Basicos/index";
import CardHoc from "../../Hoc/cardHoc";
import MenuTable from "../../Menu/menuTable";
import { connect } from 'react-redux';
import * as actionsRedux from '../../Store/Actions/index';
import { axiosApi } from '../../Basicos/axiosInstances';
import { formatLaravelDate, parseDate } from '../../Basicos/funcoes';

function montarColunas(campos, { editar, deletar }, removerOnClick, editarOnClick) {
    const colunas = campos.estrutura.map((elemento) => {
        switch (elemento.name) {
            case "cor":
                return {
                    Header: elemento.label,
                    Cell: ({ row }) => (
                        <div style={{ background: row.original.cor, width: -1, height: 32, borderRadius: 100 }} />)
                };

            default:
                return {
                    Header: elemento.label,
                    accessor: elemento.name,
                }
        }
    });

    if (editar || deletar) {
        colunas.push({
            Cell: ({ row }) => (
                <MenuTable
                    iconEdit={editar}
                    iconRemover={deletar}
                    removerOnClick={() => removerOnClick(row.original.id)}
                    editarOnClick={() => editarOnClick(row.original.id)}
                />
            ),
            resizable: false,
            accessor: "acoes",
            style: { width: 64 }
        });
    }
    return colunas;
}

function CardCrud({
    ativarAlert,
    subFuncionalidade: {
        descricao,
        campos,
        acoes
    },
}) {
    const [dadosForm, setDadosForm] = useState({
        descricao: '',
        data: ''
    });

    const [isLoading, setIsLoading] = useState(true);

    const [feriados, setFeriados] = useState([])

    const getFeriados = async () => {
        setIsLoading(true)
        try {
            const { data } = await axiosApi.get('/auxiliares/feriado')
            setFeriados(data.dados)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            if (error.response.status === 403) {
                ativarAlert(400, error?.response?.data?.mensagem);
            }

        }
    }

    useEffect(() => getFeriados(), []);

    const alterarOnClick = (id) => {
        const elemento = feriados.find((element) => element.id === id);
        setDadosForm(elemento);
    }

    const removerOnClick = async (id) => {
        setIsLoading(true)
        try {
            await axiosApi.delete(`/auxiliares/feriado/${id}`);
            setFeriados(prev => prev.filter(el => el.id !== id));
            ativarAlert('remover', 'sucesso')
        } catch (error) {
            ativarAlert('remover', 'error')
            console.log(error)
        }
        setIsLoading(false)
    }

    const onChange = (nome, valor) => {
        setDadosForm({
            ...dadosForm,
            [nome]: valor
        });
    }
    const colunas = React.useMemo(() => montarColunas(campos, acoes, removerOnClick, alterarOnClick), [feriados])

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            if (dadosForm.id) {
                const { data } = await axiosApi.put(`/auxiliares/feriado/${dadosForm.id}`, dadosForm);
                setFeriados(data.dados)
                ativarAlert('editar', 'sucesso')
            } else {
                const { data } = await axiosApi.post('/auxiliares/feriado', dadosForm);
                setFeriados(data.dados)
                ativarAlert('adicionar', 'sucesso')
            }
            setDadosForm({})
        } catch (error) {
            ativarAlert(400, 'Algo não saiu como esperado')
            console.log(error)
        }
        setIsLoading(false)
    }

    return (
        <Fragment>
            <Col xs={12} sm={12} md={5} lg={6} xl={7} className="d-flex mb-3 header-legenda text-header">
                <h3><strong> {descricao.toUpperCase()} </strong></h3>
            </Col>
            <Col xs={12} sm={12} md={7} lg={6} xl={5} className="header-acoes mb-3">
                <div id="pesquisar-wrap"></div>
            </Col>

            <NewLoader isActive={isLoading} fixed>
                <Fragment>
                    <Col sm={4} md={4}>
                        <Form>
                            <InputForm
                                type="text"
                                name="descricao"
                                label="Descrição"
                                value={dadosForm?.descricao ?? ''}
                                onChange={onChange}
                            />
                            <DateForm
                                onChange={onChange}
                                name="data"

                                value={{ from: parseDate(dadosForm?.data, formatLaravelDate) }}
                                dataInicial={{
                                    diasFuturos: true,
                                    "label": "Data",
                                    "readOnly": false,
                                    "placeholder": "dd/mm/aaaa",
                                    "style": {
                                        "labelClass": "label-conteudoInferior",
                                        "elementoClass": "",
                                        "col": {
                                            "xs": 12,
                                            "sm": 12,
                                            "md": 12,
                                            "lg": 12,
                                            "xl": 12
                                        }
                                    }
                                }
                                }
                            />
                            <Col className="align-content-middle">
                                <Button className="mx-3" onClick={() => setDadosForm({})}>Cancelar</Button>
                                <Button className="mx-3" onClick={onSubmit}>Salvar</Button>
                            </Col>

                        </Form>
                    </Col>
                    <Col sm={8} md={8}>
                        <Tabela
                            pesquisarGlobal
                            columns={colunas}
                            data={feriados}
                        />
                    </Col>
                </Fragment>
            </NewLoader>

        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        ativarAlert: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao))
    }
}

export default connect(null, mapDispatchToProps)(CardHoc(CardCrud));
