import React from 'react';
import { Card, Col } from "reactstrap";
import CardHoc from "../../Hoc/cardHoc";



function CardTipoDeData({
     calendario, semestre
}) {
    return (
        <Col>
            <div
                className="align-content-middle mb-3"
                style={{
                    margin: "0rem 1rem",
                    borderBottom: "1px solid var(--cor-border-secundario)",
                    padding: ".7rem"
                }}
            >
                <h6>
                    <strong>Informações do Calendário  </strong>
                    
                </h6>
            </div>
            <div>
                <strong>Período Letivo: </strong> <span>{calendario?.periodo_letivo?.descricao}</span>
            </div>
            <div>
                <strong>Curso: </strong> <span>{calendario?.curso?.nome}</span>
            </div>
            <div>
                <strong>Calendário: </strong><span>{calendario?.descricao}</span>
            </div>
            <div>
                <strong>Semestres: </strong><span>{semestre && semestre.map((semestre, indice) =>
                     (indice > 0) ?
                     <row key={indice}>
                         <spam>, {semestre.semestre.descricao}</spam>
                     </row>
                     :
                     <spam>{semestre.semestre.descricao}</spam>
             )
                }</span>
            </div>
        </Col>
    );
}

export default CardHoc(CardTipoDeData);
