import React from 'react'
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import { dateAndHourLaravel } from '../funcoes';

const PopOverLogAula = ({ id, aula, logs }) => {
    return (
        <UncontrolledPopover trigger="hover" placement="top" target={`pop3${id}`} style={{ width: '360px' }}>
            <PopoverHeader>Histórico de Mudanças</PopoverHeader>
            <PopoverBody>
                <span className='titulo-aula-log'>{aula?.aula != null ? aula.aula.titulo : aula?.rodizio?.servico.descricao}</span>

                <div className='logs-historico'>
                    {logs?.map((log) => {
                        return (
                            <ul className='ul-log'>
                                <span className='fonte-negrito'>{dateAndHourLaravel(log.created_at)}</span> - {log.descricao}
                            </ul>
                        )
                    })}
                </div>
            </PopoverBody>
        </UncontrolledPopover >
    )
}

export default React.memo(PopOverLogAula);