import * as tiposActions from '../Actions/tiposActions'
import { axiosApi } from '../../Basicos/axiosInstances'

export const atualizarDados = (url, props) => {
  let urlLowerCase = url.toLowerCase()
  return dispatch => {
    axiosApi.get('/' + urlLowerCase)
      .then((response) => {
        return dispatch(atualizarDadosEmpresa({
          [props]: response.data
        }
        ))
      })
      .catch((error) => {

      })
  }
}

export const atualizarInicial = () => {
  return dispatch => {
    Promise.all([
      axiosApi.get('/empresa'),
      axiosApi.get('/solucao'),
      axiosApi.get('/funcionalidade'),
      axiosApi.get('/subfuncionalidade'),
      axiosApi.get('/nivel')
    ])
      .then(([empresas, solucao, funcionalidades, subFuncionalidades, nivel]) => {
        return dispatch(atualizarDadosEmpresa({
          empresas: empresas.data,
          solucao: solucao.data,
          funcionalidades: funcionalidades.data,
          subFuncionalidades: subFuncionalidades.data,
          niveis: nivel.data
        }
        ))
      }).catch((error) => {
        atualizarInicial()
        // console.log(error.response)
      })
  }
}
export const atualizarDadosEmpresa = (dados) => {
  return {
    type: tiposActions.ATUALIZAR_EMPRESA,
    dados: dados
  }
}



