import React, { useState } from 'react'
import { Col, Row, Button, Modal } from 'reactstrap';
import { HeaderModal } from "../Basicos/index"

const ModalAlertGrupos = ({ isOpen, setIsOpen, mensagem, titulo, ace, cha, tut }) => {

    const toggleAlert = () => setIsOpen(!isOpen);

    return <Modal
        isOpen={isOpen}
        toggle={toggleAlert}
        size={'lg'}
        centered={true}
        className={"modal-map-body"}
        backdrop="static"
    >
        <Row noGutters={true}>
            <HeaderModal sm={12} toggle={toggleAlert} titulo={titulo.toUpperCase()} />
            <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                {mensagem}
            </Col>
            <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                {
                    ace?.length > 0 ?
                        `ACE: 
                        ${ace?.map(grupo => {
                            return ` ${grupo?.label}`
                        })}`
                        : ''
                }
            </Col>
            <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                {
                    cha?.length > 0 ?
                        `CHA: 
                        ${cha?.map(grupo => {
                            return ` ${grupo?.label}`
                        })}`
                        : ''
                }
            </Col>
            <Col sm={12} style={{ fontSize: '0.9rem', padding: '8px', color: 'var(--cor-text-secundario)', textAlign: 'center' }}>
                {
                    tut?.length > 0 ?
                        `TUT: 
                        ${tut?.map(grupo => {
                            return ` ${grupo?.label}`
                        })}`
                        : ''
                }
            </Col>
            <Col sm={12} className='align-content-middle' style={{ padding: '6px' }}>
                <Button outline color="primary" onClick={toggleAlert} className={"float-left btnHeader btnHeader-danger m-0"}>
                    OK
                </Button>
            </Col>
        </Row>
    </Modal>
}
export default ModalAlertGrupos;